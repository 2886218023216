








































import { Component, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";
import moment, { Moment } from "moment";

import App from "@/app/modules/_global/classes/App";

type UserData = {
  user: object;
  person: {
    salutation_id?: string;
  };
  personal_profile: {
    birthday?: string;
  };
};

type Messages = {
  success: null | string;
  errors: null | object;
};

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    Form_: () => import("@/app/modules/profile/components/ProfileForm.vue")
  }
})
export default class ProfilePage extends Vue {
  constructor() {
    super();

    GA.addCustomDimension(1, `${this.storedUserData?.user.id}`);
    GA.trackView("Profile");
  }

  userData: UserData = {
    user: {},
    person: {},
    personal_profile: {}
  };

  messages: Messages = {
    success: null,
    errors: null
  };

  userDataEditing = false;

  get appData() {
    return this.$store.getters["app/data"];
  }

  get appLayout() {
    try {
      return this.appData.layout;
    } catch (e) {
      return null;
    }
  }

  get storedUserData() {
    return this.$store.getters["profile/data"];
  }

  editUserData() {
    this.userDataEditing = true;
  }

  updateUserData() {
    let birthday: Moment | string = moment(this.userData.personal_profile.birthday, "DD.MM.YYYY");

    if (birthday.isValid()) birthday = birthday.format("YYYY-MM-DD");
    else birthday = "";

    this.userData = {
      user: { ...this.userData.user },
      person: { ...this.userData.person },
      personal_profile: {
        ...this.userData.personal_profile,
        birthday
      }
    };

    this.scrollTop();

    // TODO: Remove this during the refactoring
    delete this.userData.person.salutation_id;

    this.$store
      .dispatch("profile/update", this.userData)
      .then(() => {
        App.init({ auth: true });

        this.messages = {
          success: this.$t("SNACKBAR.SUCCESS") as string,
          errors: null
        };

        this.userData.personal_profile.birthday = moment(
          this.userData.personal_profile.birthday
        ).format("DD.MM.YYYY");

        setTimeout(() => (this.messages.success = null), 3000);

        if (this.$route.query.redirect) {
          this.$router.push({ path: `${this.$route.query.redirect}` });
        }
      })
      .catch(error => {
        this.messages = {
          success: null,
          errors: error.response.data.errors
        };
      });
  }

  scrollTop() {
    const card = document.getElementById("card");
    if (card) card.scrollIntoView({ behavior: "smooth" });
  }
}
